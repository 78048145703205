import React, { useState, useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import { useWebSocket } from '../../websocketcomps/WebSocketContext';

const Timer = () => {
    const [time, setTime] = useState(0);
    const lastMessage = useWebSocket();
    const timerRef = useRef(null);
  
    useEffect(() => {
      if (lastMessage) {
        if (lastMessage.action === 'COMIC_START') {
          setTime(0);
          if (timerRef.current) {
            clearInterval(timerRef.current);
          }
          timerRef.current = setInterval(() => {
            setTime((prevTime) => prevTime + 1);
          }, 1000);
        }
  
        if (lastMessage.action.startsWith('END_ROUND') || lastMessage.action.startsWith('CANCEL_ROUND')) {
          setTime(0);
          if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
          }
        }
      }
    }, [lastMessage]);
  
    useEffect(() => {
      return () => {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      };
    }, []);
  
    const formatTime = (totalSeconds) => {
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };
  
    return (
      <Typography variant="h6">
        Timer: {formatTime(time)}
      </Typography>
    );
  };
  

export default Timer;
