import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StartSessionModal from './StartSessionModal';
import EndSessionModal from './EndSessionModal';
import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const GameSessions = ({ setSessionStatus, sessionActive, setSessionActive, currentSessionId, setCurrentSessionId }) => {
    const navigate = useNavigate();
    const [isStartModalOpen, setIsStartModalOpen] = useState(false);
    const [isEndModalOpen, setIsEndModalOpen] = useState(false);
    const [isWeekendModeDialogOpen, setIsWeekendModeDialogOpen] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    useEffect(() => {
        const checkIfSessionActive = async () => {
            try {
                const response = await fetch(`${apiUrl}/GameSession/active`);
                if (response.ok) {
                    const { id, isActive } = await response.json();
                    setSessionActive(isActive);
                    setCurrentSessionId(id);
                }
            } catch (error) {
                console.error('Failed to check session status:', error);
            }
        };
        checkIfSessionActive();
    }, []);

    const startSession = async (sessionData) => {
        try {
            const response = await fetch(`${apiUrl}/GameSession/start`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(sessionData),
            });
            if (response.ok) {
                const result = await response.json();
                setSessionStatus({ message: 'Active session started with ID: ' + result.id, type: 'success' });
                setSessionActive(true);
                setCurrentSessionId(result.id);
                return { success: true };
            } else {
                console.error('Failed to start session');
                return { success: false };
            }
        } catch (error) {
            console.error('Error starting session', error);
            return { success: false };
        }
    };

    const endSession = async (data) => {
        if (!currentSessionId) return;
        try {
            const response = await fetch(`${apiUrl}/GameSession/end/${currentSessionId}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    sales: parseFloat(data.sales),
                    postGameNotes: data.postGameNotes
                }),
            });

            if (response.ok) {
                setSessionStatus({ message: 'No active session', type: 'normal' });
                setSessionActive(false);
                setCurrentSessionId(null);
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || "Failed to end the session.");
            }
            console.log("Session ended successfully.");
        } catch (error) {
            setSessionStatus({ message: 'Error: ' + error.message, type: 'error' });
            console.error("Error ending session:", error.message);
            alert(error.message);
        }
    };

    const enterWeekendMode = async () => {
        try {
            const response = await fetch(`${apiUrl}/GameState/state`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    state: 'weekend',
                    message: '',
                    roundId: '', // Ensure this matches the backend model's property name
                    timestamp: null // This can be set by the backend if not provided
                }),
            });
    
            if (response.ok) {
                alert('Weekend mode activated successfully.');
                setIsWeekendModeDialogOpen(true); // Set this to true to open the dialog
            } else {
                console.error('Failed to enter weekend mode');
                alert('Failed to enter weekend mode.');
            }
        } catch (error) {
            console.error('Error entering weekend mode', error);
            alert('Error entering weekend mode.');
        } finally {
            setIsWeekendModeDialogOpen(false); // This ensures the dialog closes after the operation
        }
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h6">Game Sessions Management</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Button variant="contained" color="primary" onClick={() => setIsStartModalOpen(true)} disabled={sessionActive}>
                    Start New Session
                </Button>
                <Button variant="contained" color="secondary" onClick={() => setIsEndModalOpen(true)} disabled={!sessionActive}>
                    End Session
                </Button>
                {!sessionActive && (
                    <Button variant="contained" color="error" onClick={() => setIsWeekendModeDialogOpen(true)}>
                        Enter Weekend Mode
                    </Button>
                )}
                <StartSessionModal isOpen={isStartModalOpen} onClose={() => setIsStartModalOpen(false)} onStart={startSession} />
                <EndSessionModal isOpen={isEndModalOpen} onClose={() => setIsEndModalOpen(false)} onEnd={endSession} />
                <Button variant="outlined" onClick={() => navigate('/admin')}>
                    Back to Admin Dashboard
                </Button>
            </Box>

            <Dialog
                open={isWeekendModeDialogOpen}
                onClose={() => setIsWeekendModeDialogOpen(false)}
            >
                <DialogTitle>Confirm Weekend Mode</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to enter weekend mode?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsWeekendModeDialogOpen(false)}>Cancel</Button>
                    <Button onClick={enterWeekendMode} color="error">Confirm</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default GameSessions;
